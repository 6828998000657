<template>
  <div
    :style="{
      'background-color': '#ffffff',
      'background-size': 'cover',
    }"
  >
    <CContainer
      class="d-flex justify-content-center align-items-center min-vh-100"
    >
      <CRow class="justify-content-center col-md-12">
        <CCard
          color="white"
          text-color="dark"
          class="text-center col-md-6"
          body-wrapper
        >
          <CAlert v-if="isAlert != ''" color="danger">{{ isAlert }}</CAlert>
          <CRow>
            <CCol md="12">
              <img src="/sss.png" class="img-fluid" />

              <CForm class="mt-4">
                <input
                  v-model.number="authCode"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="numeric"
                  placeholder="กรอกรหัสยืนยันตัวตน"
                  class="form-control"
                />

                <CRow class="mt-2">
                  <CCol col="12" v-if="loadingButton === true">
                    <CButton @click="login" block color="success" class="px-4">
                      {{ $t('confirm') }}
                    </CButton>
                  </CCol>
                  <CCol col="12" v-else-if="loadingButton === false">
                    <CButton block color="success" disabled>
                      <CSpinner color="white" size="sm" />
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>
              <hr />
              <p class="text-danger font-weight-normal" v-if="isError === true">
                {{ $t('passwordNotCorrect') }}
              </p>
              <p class="text-dark" @click="openQR()">
                {{ $t('requestQR') }}
              </p>
            </CCol>
          </CRow>
        </CCard>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import axios from '../../services/auth'
import liff from '@line/liff'

export default {
  data() {
    return {
      isBusy: true,
      loadingButton: true,
      authCode: '',
      isError: false,
      isAlert: '',
    }
  },
  computed: {},
  created() {
    liff
      .init({ liffId: process.env.VUE_APP_LIFF_ID })
      .then(() => {
        if (
          liff.getContext().type !== 'none' &&
          liff.getContext().type !== 'external'
        ) {
          if (!liff.isLoggedIn()) {
            liff.login()
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
  },
  methods: {
    login() {
      const authCode = this.authCode

      axios
        .get('/auth/v1.0/authcode/' + authCode)
        .then((res) => {
          if (res.data.error.code === 0) {
            this.isError = false
            let token = res.data.data.token
            let idToken = res.data.data.refreshToken
            let uid = res.data.data.uid
            let userId = liff.getContext().userId

            let data = {
              uid: uid,
              idToken: idToken,
              token: token,
              userId: userId,
            }
            this.loadingButton = true

            if (liff.getContext().type !== 'external') {
              this.getRichMenu(data)
            } else {
              this.isAlert =
                'คุณสามารถใช้งานได้บน Line Official: Silom Smart Connect เท่านั้น'
            }
          } else {
            this.isError = true
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    getRichMenu(data) {
      const userId = data.userId

      axios({
        method: 'post',
        url: process.env.VUE_APP_LINE_WEBHOOK + '/line/set/richmenu/' + userId,
      })
        .then((response) => {
          this.$store.dispatch('loginWithLineLIFF', data)
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    openQR() {
      liff.openWindow({
        url: process.env.VUE_APP_WEB_URL + '/silom-connect',
        external: true,
      })
    },
  },
}
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
